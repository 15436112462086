import React, { useContext } from 'react';
import { orangeColor } from '../../components/ChartColors';
import Tooltip from '../../components/Tooltip';
import { FormAContext } from '../../context/FormAContext';
import BarChart from '../../widgets/BarChart';
import { BarDatum, BarItem, BarItemProps } from '@nivo/bar';

function formatPercentage(percentage: number | null) {
    return `${percentage?.toFixed(2)}%`;
}


const CustomBarComponent = (props: BarItemProps<BarDatum>) => {
    const bar = props.bar;

    const { x, y, width , data } = bar



    return <>
        <BarItem {...props} bar={{ ...bar, width: Math.min(bar.width, 80) }} />
        <g>
            <text
                x={x + width / 2}
                y={y - 12} // the important bit!!
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: 16,
                    pointerEvents: 'none',
                    fill: '#262626',
                    fontWeight: 400,
                    lineHeight: '19px',
                }}
            >
                {`${data.value.toFixed(2)} %`}
            </text>
        </g>
    </>
}

function CoreChart() {
    const { results } = useContext(FormAContext);
    if (results) {
        const effect = results.effect_on_beverage_price;
        return <BarChart
            margin={{ top: 10, right: 0, bottom: 40, left: 80 }}
            colors={orangeColor}
            colorBy="id"
            data={[
                    // effects ic and fc are a percent expressed as fraction, so multiply with 100
                    { type: "FC", "FC": effect.result_fc * 100 },
                    { type: "IC", "IC": effect.result_ic * 100 },
            ]}
            indexBy="type"
            keys={["FC", "IC"]}

            axisMarginHigh

            labelTextColor="white"
            padding={0.5}
            enableGridX={false}
            enableGridY={false}
            indexScale={{ type: 'band', round: true, }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                tickValues: 6,
                format: value => formatPercentage(value),
            }}
            axisBottom={{
                tickSize: 0,
            }}
            theme={{
                fontSize: 16,
                textColor: '#474747',
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            label={d => formatPercentage(d.value)}
            enableLabel={false}
            barComponent={CustomBarComponent}
            tooltip={e =>
                <Tooltip>
                    {e.id ? `${e.id}: ` : ""}
                    {formatPercentage(e.value)}
                </Tooltip>
            }
        />
    }
    else {
        return <div> No data </div>;
    }
}

export const ChangeInPriceChart = () => {
    return <>
        <div id="chart" style={{ height: "360px", width: "100%" }}>
            <CoreChart />
        </div>
    </>

}
